import React, { forwardRef, useCallback, useRef, ReactNode } from "react";
import ReactPopup from "reactjs-popup";
import { PopupActions, PopupProps } from "reactjs-popup/dist/types";
import { useCombinedRefs } from "../../../lib/hooks/useCombinedRefs";

import { Icon } from "../Icon/Icon";

import "./Popup.scss";

type PopupComponentProps = PopupProps & {
  title?: string;
  description?: string;
  hideCloseButton?: boolean;
  children: ReactNode | ((props: { handleClose: () => void }) => ReactNode);
};

export const Popup = forwardRef<PopupActions, PopupComponentProps>(
  ({ title, hideCloseButton, children, description, ...props }, ref) => {
    const popupRef = useRef<PopupActions>(null);
    const combinedRefs = useCombinedRefs<PopupActions>(ref, popupRef);

    const handleClose = useCallback(() => {
      if (combinedRefs.current) {
        combinedRefs.current.close();
      }
    }, [combinedRefs]);

    return (
      <ReactPopup ref={combinedRefs} arrow={false} offsetY={16} {...props}>
        <div className="PopupHeader">
          {!!title && <h5 className="PopupHeader__title">{title}</h5>}
          {!!description && (
            <span className="PopupHeader__description">{description}</span>
          )}
        </div>

        {!hideCloseButton && (
          <span className="PopupCloseButton" onClick={handleClose}>
            <Icon icon="close-icon" />
          </span>
        )}

        {typeof children === "function"
          ? (children as (props: { handleClose: () => void }) => ReactNode)({
              handleClose,
            })
          : children}
      </ReactPopup>
    );
  }
);
