import React from "react";
import { toast } from "react-toastify";
import { useFloorsRequests } from "../../../../api/graphql/floors/useFloorsRequests";
import { Map } from "../../../../api/graphql/floors/floors";
import { Input } from "../../../shared/Input/Input";
import { Switch } from "../../../shared/Switch/Switch";
import { LabelWithInformationBox } from "../../../shared/LabelWithInformationBox/LabelWithInformationBox";
import { InputDescription } from "../../../shared/InputDescription/InputDescription";

import "./FloorSettingsPopupForm.scss";

interface Props {
  map: Map;
}

export const FloorSettingsPopupForm = ({ map }: Props) => {
  const { updateMapFloor } = useFloorsRequests();

  const validateInput = (inputValue: number) => {
    if (inputValue < 10 || inputValue > 100) {
      toast.error("Please add a value between 10 and 100");
      return;
    }

    return inputValue;
  };

  const handleUpdateMap = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateMapFloor({
      ...map,
      opacity: Number(validateInput(Number(event.target.value))),
    });
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleUpdateMap(event);
    }
  };

  return (
    <div className="FloorSettingsPopupForm">
      <div className="FloorSettingsPopupForm__group">
        <LabelWithInformationBox
          title="Map artwork passthrough"
          icon="passthrough-icon"
        />
        <span className="FloorSettingsPopupForm__group--value">%</span>
        <Input
          type="number"
          nam="opacity"
          pattern="[0-9]*"
          defaultValue={map?.opacity}
          onKeyDown={handleKeyDown}
          tabIndex={-1}
          autoFocus={false}
          onBlur={(e) => {
            if (+e.target.value === map?.opacity) {
              return;
            }

            handleUpdateMap(e);
          }}
        />
      </div>

      <div className="FloorSettingsPopupForm__group">
        <LabelWithInformationBox
          title="Snap elements to grid"
          icon="grid-icon"
        />
        <Switch
          value={map?.showGridline}
          name="showGirdline"
          onChange={(value) => {
            updateMapFloor({
              ...map,
              showGridline: value,
            });
          }}
        />
      </div>

      <div className="divider"></div>

      <div className="FloorSettingsPopupForm__group">
        <LabelWithInformationBox
          title="Show room name labels"
          icon="label-icon"
        />
        <Switch
          value={map?.showTooltips}
          name="showTooltips"
          onChange={(value) => {
            updateMapFloor({
              ...map,
              showTooltips: value,
            });
          }}
        />
      </div>

      <div className="FloorSettingsPopupForm__group pb-1">
        <LabelWithInformationBox
          title="Hide markers for shapes"
          icon="eye-slash-regular"
        />
        <Switch
          name="showRoomMarkers"
          value={!map?.showRoomMarkers}
          onChange={(value) => {
            updateMapFloor({
              ...map,
              showRoomMarkers: !value,
            });
          }}
        />
      </div>
      <InputDescription
        titleColor="info"
        title="We recommend hiding markers when using shapes and room name labels together."
        className="mt-0"
      />
    </div>
  );
};
