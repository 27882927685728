import React from "react";
import cs from "classnames";
import { IconDefinition } from "../../../shared/Icon/Icon.generated";
import { Icon } from "../../../shared/Icon/Icon";

interface Props {
  icon: IconDefinition;
  className?: string;
  text?: string;
  element?: React.ReactNode;
  showTooltip?: boolean;
}

export const ReservationCardElement = ({
  icon,
  className,
  text,
  element,
  showTooltip,
}: Props) => {
  return (
    <div className={cs("ReservationCardElement flex-a-center", className)}>
      <Icon icon={icon} />

      {text ? (
        <span className="pl-2 d-block overflow-text">{text}</span>
      ) : (
        element
      )}

      {showTooltip && (
        <div className="ReservationCardElement__tooltip">
          {text ? <span className="text-wrap">{text}</span> : element}
        </div>
      )}
    </div>
  );
};
