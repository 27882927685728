import React, { PropsWithChildren } from "react";
import { Tooltip } from "react-leaflet";
import { trimText } from "../../../../lib/helpers/trimText";

export const ShapeTooltip = ({
  name,
  className,
}: PropsWithChildren<{
  name: string;
  className?: string;
}>) => {
  return (
    <Tooltip
      className={`room-permanent-tooltip ${className}`}
      direction="center"
      offset={[0, 0]}
      opacity={1}
      permanent
    >
      <div>
        <span>{trimText(name, 18)}</span>
      </div>
    </Tooltip>
  );
};
