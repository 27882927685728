import React from "react";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { useFormatReservationDate } from "../../lib/datePickerHelper";
import { utcToZonedTime } from "date-fns-tz";
import { dateToHoursAndMinutes } from "../../lib/dateInputConvert";

import { Button } from "../../../shared/Button/Button";
import { Icon } from "../../../shared/Icon/Icon";
import { LineDivider } from "../../../shared/LineDivider/LineDivider";
import { ExtendedProps } from "../../Reservations/ReservationList/ReservationListActions";

import { ModalRow } from "../../BookingsMap/BookingsMapModal/shared/ModalRow";
import mobileApp from "../../../../assets/images/MobileApp.png";
import qrCode from "../../../../assets/images/qr-code-scan.png";

export const CheckInRequired = ({
  // workplaceId,
  startTime,
  endTime,
  name,
  isOpen,
  toggleModal,
  checkInTime,
}: ExtendedProps) => {
  const { timeZoneFormatted, timeZone } = useTimeZoneContext();
  const utc = timeZoneFormatted.split(" ")[0];
  const definedUtc = utc.replace(":00", "");

  return (
    <Modal isOpen={isOpen} onToggle={toggleModal} className="BookingsModal">
      <ModalHeader>
        <span onClick={toggleModal} className="btn-close">
          <Icon icon="close-icon" />
        </span>
      </ModalHeader>

      <h5 className="mt-4 mb-3">DESK QR SCAN REQUIRED</h5>

      <LineDivider />

      <div className="flex-a-center">
        <img src={qrCode} alt="QR Code" className="PromotionCard__qrCode" />
        <img
          src={mobileApp}
          alt="Mobile app"
          className="PromotionCard__mobileApp"
        />
      </div>

      <h5 className="w-90 mb-2 mt-1 text-center text-pro">
        Head over to the GOGET mobile app to complete check-in at the desk
      </h5>

      <span className="text-secondary text-center">
        Desk check-in opens{" "}
        <span className="text-pro">{checkInTime || 1} hour(s)</span> prior to
        the reservation start time
      </span>

      <div className="BookingsModal__body">
        <ModalRow icon="desk_icon_18x18" utc={definedUtc} title={name} />

        <ModalRow
          icon="calendar-bookings"
          title={useFormatReservationDate(
            utcToZonedTime(new Date(startTime), timeZone)
          )}
          startTime={dateToHoursAndMinutes(new Date(startTime), timeZone)}
          endTime={dateToHoursAndMinutes(new Date(endTime), timeZone)}
          // allDay={allDay}
          utc={definedUtc}
        />
      </div>

      <ModalFooter className="p-0 w-full mt-2">
        <Button
          size="small"
          color="business"
          title="OK, close"
          onClick={toggleModal}
          className="w-90 max-w-none"
        />
      </ModalFooter>
    </Modal>
  );
};
