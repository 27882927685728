import { LatLngLiteral } from "leaflet";
import React, { useEffect, useRef } from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";
import { trimText } from "../../../../lib/helpers/trimText";

export interface Props {
  position: LatLngLiteral;
  defaultOpen?: boolean;
  onOpen?: () => void;
  icon: L.Icon | L.DivIcon;
  children?: React.ReactNode;
  name: string;
  type: string;
  isBooked: boolean;
}

export const BookingsFloorMapMarker = ({
  position,
  children,
  defaultOpen,
  icon,
  name,
  type,
  isBooked,
}: Props) => {
  const markerRef = useRef<L.Marker>(null);

  useEffect(() => {
    let currentMarker = markerRef.current;

    if (currentMarker === null) {
      return;
    }

    if (defaultOpen === undefined && currentMarker.isPopupOpen()) {
      currentMarker.closePopup();
      return;
    }

    if (defaultOpen) {
      if (currentMarker.isPopupOpen()) {
        currentMarker.closePopup();
        return;
      }
      currentMarker.openPopup();
    }
  }, [defaultOpen]);

  return (
    <Marker icon={icon} ref={markerRef} position={position} draggable={false}>
      {type === "Room" && (
        <Tooltip
          className={`room-permanent-tooltip ${isBooked ? "room-booked" : ""}`}
          direction="bottom"
          offset={[0, 20]}
          opacity={1}
          permanent
        >
          <div>
            <span>{trimText(name, 18)}</span>
          </div>
        </Tooltip>
      )}
      <Popup>{children}</Popup>
    </Marker>
  );
};
