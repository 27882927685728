import React from "react";
import { useBookingsContext } from "../../Context/BookingsContext";
import { Tags } from "../../../../api/grpc/workplaces/workplaces";
import { Icon } from "../../../shared/Icon/Icon";

import "./TagFilter.scss";

interface Props {
  data?: Tags;
  loading: boolean;
  error: any;
  onClick: (e: string) => void;
}

export const TagFilter = ({ loading, error, data, onClick }: Props) => {
  const { activeTagsFilter, handleActiveTagsFilter } = useBookingsContext();

  const handleTagClick = (tag: string) => {
    handleActiveTagsFilter(tag);

    return onClick(tag);
  };

  return (
    <div className="TagFilter">
      <div className="flex-a-center TagFilter__head mb-4">
        <Icon icon="tag-reserve" />

        <h6 className="pl-2">Tags filter selection</h6>
      </div>
      {loading ? (
        <div>Fetching tags...</div>
      ) : error ? (
        <div>Error fetching tags, please try again</div>
      ) : (
        <div className="TagFilter__content">
          {data && data.tags.length <= 0 ? (
            <div>This location has no added tags.</div>
          ) : (
            data?.tags.map((tag, idx) => (
              <div
                className={`TagFilter__item ${
                  activeTagsFilter.includes(tag) ? "active" : " "
                }`}
                onClick={() => handleTagClick(tag)}
                key={`${tag}-${idx}`}
              >
                <div className="flex-a-center">
                  <Icon icon="tag-reserve" />
                  <span>{tag}</span>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};
