import React, { useEffect, useMemo, useState } from "react";
import { ModalBody, Form, FormGroup, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import {
  addMinutes,
  differenceInDays,
  differenceInMinutes,
  endOfDay,
  format,
  parseISO,
  setHours,
  setMinutes,
  setSeconds,
  startOfDay,
  subMinutes,
} from "date-fns";
import { toast } from "react-toastify";
import { useApolloClient } from "@apollo/client";
import { useBookingsContext } from "../../Context/BookingsContext";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";
import { useBookingsMapContext } from "../Context/BookingsMapContext";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { useCalendarContext } from "../../../../lib/context/Calendar/CalendarContext";
import { useFormatReservationDate as formatReservationDate } from "../../lib/datePickerHelper";
import { useCalendarRequests } from "../../../../api/grpc/calendarprovider/useCalendarRequests";
import { useRouter } from "../../../../lib/hooks/useRouter";
import { useCheckUserPermissions } from "../../../../lib/hooks/useCheckUserPermissions";
import { useAllReservationContext } from "../../../AllReservations/shared/context/AllReservationContext/AllReservationContext";
import { useWorkplacesClient } from "../../../../api/grpc/workplaces/useWorkplacesClient";
import { useReserveHelpers } from "../../lib/reserveHelpers";

import { capitalizeFirstLetter } from "../../../../lib/helpers/capitalizeFirstLetter";
import {
  getWorkplaceFragment,
  getWorkplaceFragmentName,
} from "../../../Workplaces/helpers/getWorkplaceFragment";
import {
  attachHoursToDate,
  dateToHoursAndMinutes,
} from "../../lib/dateInputConvert";
import { defineDatesPicker } from "../../lib/defineDatesPicker";
import { defineLocationPath } from "../../helpers/defineLocationPath";

import { Button } from "../../../shared/Button/Button";
import { Icon } from "../../../shared/Icon/Icon";
import { Input } from "../../../shared/Input/Input";
import { Attendee } from "../../../../api/grpc/booking/ggevent/ggevent";
import { CalendarCredentialsStatus } from "../../../../api/grpc/calendarprovider/calendarprovider";
import { MainResources } from "../../../../lib/types/main.types";
import { defaultAttendee } from "../../../../lib/helpers/defaultRoomEvent";
import { DaySelectorInput } from "../../../shared/Forms/DaySelectorInput/DaySelectorInput";
import { RoomBookingInputs } from "../../form/RoomBookingInputs/RoomBookingInputs";
import { Switch } from "../../../shared/Switch/Switch";
import { FindPeopleButton } from "../../form/FindPeopleButton/FindPeopleButton";
import { EndTimeInputs } from "../../form/EndTimeInputs/EndTimeInputs";
import { timePickerProps } from "../../helpers/timePickerProps";
import { SearchDesk } from "../../../../api/grpc/workplaces/workplaces";
import { RolePermissions } from "../../../../api/grpc/account/account";
import { BookingsModal } from "../../shared/BookingsModal/BookingsModal";
import {
  BOOKING_INTERVAL,
  ERROR_STRINGS,
  GENERAL_STRINGS,
  DESK_BOOKING_INTERVAL,
} from "../../../../lib/utils/constants";

interface Props {
  id: string;
  typeOfWorkplace: MainResources;
  refetch: () => void;
}

export const BookingsMapReserve = ({ id, typeOfWorkplace, refetch }: Props) => {
  const INTERVAL =
    typeOfWorkplace === "room" ? BOOKING_INTERVAL : DESK_BOOKING_INTERVAL;
  const { timeZone } = useTimeZoneContext();
  const { personalCalendar, refetchPersonalCalendar } = useCalendarContext();
  const { validateCalendarIntegrationCredentials } = useCalendarRequests();
  const { pathname } = useRouter();
  const [assignedDesk, setAssignedDesk] = useState<SearchDesk | undefined>(
    undefined
  );
  const {
    allDay,
    setAllDay,
    bookOnBehalfUser,
    setBookOnBehalfUser,
    notifyAssignee,
    setNotifyAssignee,
    day: daySelected,
    reserveLaterTime,
    dateAndTime,
    setReserveLaterTime,
  } = useBookingsMapContext();

  const { datePickOptions } = defineDatesPicker(timeZone);
  const { startDate, eventEndTime } = useAllReservationContext();

  const {
    subject,
    handleSubject,
    isOnlineMeeting,
    handleIsOnlineMeeting,
    makePrivate,
    handleMakePrivate,
    attendees,
    handleAttendees,
  } = useBookingsContext();

  const { getAssignedDesks } = useWorkplacesClient();

  const { checkUserPermission } = useCheckUserPermissions();

  const [startTime, setStartTime] = useState(
    utcToZonedTime(new Date(), timeZone)
  );
  const [endTime, setEndTime] = useState(
    utcToZonedTime(addMinutes(new Date(), INTERVAL), timeZone)
  );
  const [submitAttempt, setSubmitAttempt] = useState(false);

  const {
    handleReserveRoom,
    locationPath,
    handleReserveDesk,
    organizerName,
    isOpen,
    toggleModal,
  } = useReserveHelpers();

  const [day, setDay] = useState<string>(
    format(utcToZonedTime(new Date(), timeZone), "yyyy-MM-dd")
  );
  const [loading, setLoading] = useState(false);

  const isAllReservation = pathname.includes("all-reservations");

  const client = useApolloClient();
  const { user } = useAuthContext();

  const workplaceData = client.readFragment({
    id: `${capitalizeFirstLetter(typeOfWorkplace)}:${id}`,
    fragmentName: getWorkplaceFragmentName(
      capitalizeFirstLetter(typeOfWorkplace)
    ),
    fragment: getWorkplaceFragment(capitalizeFirstLetter(typeOfWorkplace)),
  });

  useEffect(() => {
    if (allDay) {
      setAllDay(false);
    }

    if (!!subject.length) {
      handleSubject("");
    }

    if (bookOnBehalfUser) {
      setBookOnBehalfUser(undefined);
    }

    if (notifyAssignee) {
      setNotifyAssignee(false);
    }

    if (attendees.length > 0) {
      handleAttendees([]);
    }

    if (makePrivate) {
      handleMakePrivate();
    }

    if (!isAllReservation) {
      setDay(
        format(utcToZonedTime(new Date(daySelected), timeZone), "yyyy-MM-dd")
      );

      let newDate;

      if (differenceInMinutes(new Date(), new Date(dateAndTime)) >= 0) {
        newDate = new Date();
      } else {
        newDate = new Date(dateAndTime);
      }

      const defineDate = reserveLaterTime || newDate;

      setStartTime(utcToZonedTime(new Date(defineDate), timeZone));
      setEndTime(
        utcToZonedTime(addMinutes(new Date(defineDate), INTERVAL), timeZone)
      );
    }

    if (isAllReservation) {
      if (startDate) {
        setDay(
          format(utcToZonedTime(new Date(startDate), timeZone), "yyyy-MM-dd")
        );
      }

      if (eventEndTime) {
        setStartTime(utcToZonedTime(new Date(eventEndTime), timeZone));
        setEndTime(
          utcToZonedTime(addMinutes(new Date(eventEndTime), INTERVAL), timeZone)
        );
      }
    }
  }, []);

  const handleReserveWorkplace = async ({
    showWarning,
  }: {
    showWarning: boolean;
  }) => {
    setSubmitAttempt(true);

    const maxEndTime = endOfDay(utcToZonedTime(new Date(startTime), timeZone));

    if (new Date(endTime) > maxEndTime && !allDay) {
      return toast.error(ERROR_STRINGS.endTimeNextDay);
    }

    if (!subject.length && typeOfWorkplace === "room") {
      return;
    }

    try {
      if (workplaceData.isBlocked) {
        return toast.error(
          `Reservations blocked for ${typeOfWorkplace} due to admin policy`
        );
      }
      setLoading(true);

      if (showWarning) {
        const { response } = await getAssignedDesks();

        if (response?.desks.length > 0) {
          setAssignedDesk(response.desks[0]);

          setLoading(false);

          return;
        }
      }

      const eventIsForOtherUser = isAllReservation && !!bookOnBehalfUser;

      const timeDifference = differenceInMinutes(
        utcToZonedTime(new Date(), timeZone),
        startTime
      );

      const validTimePassed = timeDifference <= 5 && timeDifference > 0;
      let zonedDay = zonedTimeToUtc(day, timeZone);

      let start = attachHoursToDate(
        utcToZonedTime(zonedDay, timeZone).toISOString(),
        validTimePassed ? utcToZonedTime(new Date(), timeZone) : startTime,
        timeZone
      );

      if (differenceInMinutes(new Date(), new Date(start)) > 5) {
        setLoading(false);
        return toast.error("Invalid From time!");
      }

      if (typeOfWorkplace === "desk") {
        await handleReserveDesk({
          accountId: eventIsForOtherUser
            ? bookOnBehalfUser.accountID
            : user?.claims.user_id || "",
          checkInReminderSend: workplaceData?.showCheckInTime,
          customerID: user?.customerid || "",
          deskId: id,
          startTime: start,
          endTime: attachHoursToDate(
            utcToZonedTime(zonedDay, timeZone).toISOString(),
            endTime,
            timeZone,
            allDay
          ),
          organizer: eventIsForOtherUser
            ? {
                ...defaultAttendee,
                email: bookOnBehalfUser.email,
              }
            : undefined,
        });

        setReserveLaterTime(undefined);
        setLoading(false);

        return;
      }

      let hasPersonalCalendar = personalCalendar.length > 0;
      let statusOfPersonalIntegrationToken;

      if (hasPersonalCalendar) {
        try {
          await refetchPersonalCalendar();

          const {
            response: { status },
          } = await validateCalendarIntegrationCredentials(
            personalCalendar[0].iD
          );

          statusOfPersonalIntegrationToken = status;
        } catch (error: any) {
          toast.error(error?.message);
          return;
        }
      }

      if (
        hasPersonalCalendar &&
        statusOfPersonalIntegrationToken ===
          CalendarCredentialsStatus.CalendarCredentialsStatusUnknown
      ) {
        return toast.info(
          "Couldn't define the status of the personal integration, please contact your administrator."
        );
      }

      const hasValidPersonalToken =
        statusOfPersonalIntegrationToken ===
        CalendarCredentialsStatus.CalendarCredentialsStatusValid;
      const isMode2Reservation = hasPersonalCalendar && hasValidPersonalToken;

      const organizerEmail = (): string => {
        if (eventIsForOtherUser) {
          return bookOnBehalfUser.email;
        }

        if (isMode2Reservation) {
          return user?.email || "";
        }

        return workplaceData?.resourceEmail;
      };

      const ateendeeEmail = (): string => {
        if (eventIsForOtherUser) {
          return bookOnBehalfUser.email;
        }

        if (isMode2Reservation) {
          return workplaceData?.resourceEmail;
        }

        return user?.email || "";
      };

      let attendee: Attendee[] = [
        {
          ...defaultAttendee,
          email: ateendeeEmail(),
        },
      ];

      attendees.map((item) => {
        return attendee.push({
          ...defaultAttendee,
          email: item,
        });
      });

      await handleReserveRoom({
        roomId: id,
        title: subject,
        attendees: attendee,
        calendarId:
          isMode2Reservation && !eventIsForOtherUser
            ? ""
            : workplaceData?.calendarId,
        calendarproviderId:
          isMode2Reservation && !eventIsForOtherUser
            ? personalCalendar[0].iD
            : workplaceData?.calendarProviderId,
        startTime: start,
        endTime: attachHoursToDate(
          utcToZonedTime(zonedDay, timeZone).toISOString(),
          endTime,
          timeZone,
          allDay
        ),
        organizerEmail: organizerEmail(),
        checkInReminderSend: !!workplaceData?.displaySettings?.checkinReminder,
        isOnlineMeeting: isOnlineMeeting,
        isPrivate: makePrivate,
      });

      setReserveLaterTime(undefined);

      setLoading(false);
    } catch (error: any) {
      toast.error(
        error.message ? error.message : ERROR_STRINGS.reservationFail
      );
    }
  };

  const filterPassedTime = (time: Date) => {
    if (
      differenceInDays(
        startOfDay(parseISO(day)),
        startOfDay(utcToZonedTime(new Date(), timeZone))
      ) > 0
    ) {
      return true;
    }
    const currentDate = utcToZonedTime(new Date(), timeZone);
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const handleDurationChange = (hour: number, minute: number) => {
    const durationInMinutes = hour * 60 + minute;
    const newEndTime = addMinutes(startTime, durationInMinutes);
    setEndTime(newEndTime);
  };

  const handleCloseConfirmModal = () => {
    toggleModal();

    refetch();
  };

  const definedUsername = useMemo(() => {
    if (bookOnBehalfUser) {
      return bookOnBehalfUser.displayName;
    }

    if (typeOfWorkplace === "desk" || !!!personalCalendar.length) {
      return user?.firstName + " " + user?.lastName;
    }

    return organizerName || "";
  }, [personalCalendar, organizerName, bookOnBehalfUser]);

  return (
    <>
      {assignedDesk && (
        <BookingsModal
          isOpen={true}
          toggle={() => setAssignedDesk(undefined)}
          title="Heads up!"
          type="warning"
          workspaceType="desk"
          day=""
          endTime=""
          startTime=""
          classNames="modal-footer-row modal-footer-row-reverse"
          locationPath={defineLocationPath(assignedDesk.locationPath)}
          userName={user?.firstName + " " + user?.lastName + " (You)"}
          workspaceName={assignedDesk?.name || ""}
          everyDay={true}
          buttonTitle="Yes"
          buttonCancelTitle="No"
          handleModalClose={() => setAssignedDesk(undefined)}
          buttonClick={() => handleReserveWorkplace({ showWarning: false })}
          disableButton={loading}
          loadSpinner={loading}
        />
      )}

      {/* confirmation modal */}
      {isOpen ? (
        <BookingsModal
          isOpen={isOpen}
          toggle={toggleModal}
          workspaceType={typeOfWorkplace}
          title="Reservation confirmed"
          icon="reservation-confimed"
          day={formatReservationDate(
            utcToZonedTime(new Date(startTime), timeZone)
          )}
          workspaceName={workplaceData.name}
          startTime={dateToHoursAndMinutes(new Date(startTime), timeZone)}
          endTime={dateToHoursAndMinutes(new Date(endTime), timeZone)}
          userName={definedUsername}
          subject={subject}
          locationPath={defineLocationPath(locationPath)}
          handleModalClose={handleCloseConfirmModal}
          type="confirm"
          allDay={allDay}
          showAttendees
        />
      ) : (
        <>
          {" "}
          <ModalBody className="w-90 p-0">
            <div className="text-center BookingsButtonsModal__info">
              {/* <span>When do you need your {workplaceData?.__typename} ?</span> */}
              <Icon
                icon={
                  workplaceData?.__typename === "Desk"
                    ? "desk_icon_18x18"
                    : "room_icon_18x18"
                }
              />

              <span>{workplaceData?.name}</span>
            </div>

            <Form className="SearchWorkplaceForm">
              {typeOfWorkplace === "room" && (
                <FormGroup className="SearchWorkplaceForm--text SearchWorkplaceForm__subject">
                  <Input
                    type="text"
                    placeholder="Subject new reservation..."
                    id="subject"
                    name="subject"
                    onChange={(e) => handleSubject(e.target.value)}
                    isTouched={submitAttempt && subject.length <= 0}
                    errorMessage={GENERAL_STRINGS.subjectRequired}
                    className={
                      submitAttempt && subject.length <= 0 ? "form-error" : ""
                    }
                  />
                </FormGroup>
              )}

              <FormGroup className="SearchWorkplaceForm--text mb-0">
                <label className="pb-2">From</label>
                <DaySelectorInput
                  value={
                    day
                      ? {
                          label: formatReservationDate(parseISO(day)),
                          value: day,
                        }
                      : datePickOptions[0]
                  }
                  onChange={(values) => {
                    if (values === null) {
                      return;
                    }

                    setDay(values.value);

                    if (allDay === true) {
                      const definedTimeEndTime = setHours(
                        setMinutes(
                          setSeconds(utcToZonedTime(new Date(), timeZone), 0),
                          0
                        ),
                        24
                      );

                      const definedTimeStartTime =
                        differenceInDays(
                          startOfDay(parseISO(values.value)),
                          startOfDay(utcToZonedTime(new Date(), timeZone))
                        ) > 0
                          ? setHours(
                              setMinutes(
                                setSeconds(
                                  utcToZonedTime(new Date(), timeZone),
                                  0
                                ),
                                0
                              ),
                              24
                            )
                          : utcToZonedTime(new Date(), timeZone);

                      setStartTime(definedTimeStartTime);
                      setEndTime(definedTimeEndTime);
                    }

                    return;
                  }}
                />
              </FormGroup>

              <FormGroup className="SearchWorkplaceForm--text">
                <DatePicker
                  {...timePickerProps}
                  selected={startTime}
                  onChange={(date) => {
                    if (!date) {
                      return;
                    }

                    setStartTime(date);

                    let diffMinutes = differenceInMinutes(date, endTime);

                    if (
                      (date >= endTime ||
                        (diffMinutes >= -BOOKING_INTERVAL &&
                          diffMinutes < 0)) &&
                      !allDay
                    ) {
                      setEndTime(addMinutes(new Date(date), BOOKING_INTERVAL));
                    }

                    return;
                  }}
                  className="pb-1 SearchWorkplaceForm__timePicker"
                  filterTime={filterPassedTime}
                />
              </FormGroup>

              <FormGroup className="SearchWorkplaceForm--text flex-a-center">
                <EndTimeInputs
                  handleDurationChange={handleDurationChange}
                  startTime={startTime}
                  endTime={endTime}
                  filterPassedTime={filterPassedTime}
                  onChange={(date) => {
                    if (!date) {
                      return;
                    }

                    setEndTime(date);

                    if (date <= startTime && !allDay) {
                      setStartTime(
                        subMinutes(new Date(date), BOOKING_INTERVAL)
                      );
                    }

                    return;
                  }}
                />
              </FormGroup>

              <div className="flex-a-center SearchWorkplaceForm__switch justify-content-center mb-3">
                <Switch
                  name="allDay"
                  title="Allday"
                  value={allDay}
                  onChange={() => {
                    const definedTimeEndTime =
                      allDay === false
                        ? setHours(
                            setMinutes(
                              setSeconds(
                                utcToZonedTime(new Date(), timeZone),
                                0
                              ),
                              0
                            ),
                            24
                          )
                        : utcToZonedTime(
                            addMinutes(new Date(), BOOKING_INTERVAL),
                            timeZone
                          );

                    const definedTimeStartTime =
                      allDay === false &&
                      differenceInDays(
                        startOfDay(parseISO(day)),
                        startOfDay(utcToZonedTime(new Date(), timeZone))
                      ) > 0
                        ? setHours(
                            setMinutes(
                              setSeconds(
                                utcToZonedTime(new Date(), timeZone),
                                0
                              ),
                              0
                            ),
                            24
                          )
                        : utcToZonedTime(new Date(), timeZone);

                    setEndTime(definedTimeEndTime);
                    setStartTime(definedTimeStartTime);

                    setAllDay((prev) => !prev);
                  }}
                />
              </div>

              {(typeOfWorkplace === "room" || isAllReservation) && (
                <FindPeopleButton
                  type={typeOfWorkplace}
                  showBookOnBehalf={
                    pathname.includes("all-reservations") &&
                    !checkUserPermission(
                      RolePermissions.RESERVATIONS_MEMBER_STYLE_VIEW
                    )
                  }
                />
              )}

              {typeOfWorkplace === "room" && (
                <RoomBookingInputs
                  makePrivate={makePrivate}
                  onChangePrivate={() => handleMakePrivate()}
                  isOnlineMeeting={isOnlineMeeting}
                  onChangeOnlineMeeting={() => handleIsOnlineMeeting()}
                />
              )}
            </Form>
          </ModalBody>
          <ModalFooter className="justify-content-center w-90">
            <Button
              title="Reserve"
              color="business"
              size="medium"
              disabled={loading}
              loadSpinner={loading}
              className="max-w-none w-100 m-0"
              onClick={() =>
                handleReserveWorkplace({
                  showWarning:
                    typeOfWorkplace === "desk" &&
                    (checkUserPermission(
                      RolePermissions.RESERVATIONS_MEMBER_STYLE_VIEW
                    ) ||
                      !isAllReservation),
                })
              }
            />
          </ModalFooter>
        </>
      )}
    </>
  );
};
