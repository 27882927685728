import { useState } from "react";
import { toast } from "react-toastify";
import { useBookingsMapContext } from "../BookingsMap/Context/BookingsMapContext";
import { useBookingRequest } from "../../../api/grpc/booking/useBookingRequest";
import { useDeskBookingRequest } from "../../../api/grpc/desk/useDeskBookingRequest";
import { useWorkplacesClient } from "../../../api/grpc/workplaces/useWorkplacesClient";
import { useRootIdQuery } from "../../../lib/hooks/useRootIdQuery";
import { useToggleModal } from "../../../lib/hooks/useToggleModal";
import { useTimeZoneContext } from "../../../lib/context/TimeZone/TimeZoneContext";
import { LocationItem } from "../../../api/grpc/workplaces/workplaces";
import {
  Attendee,
  CheckInRequiredStatus,
  EventCheckInStatus,
} from "../../../api/grpc/booking/ggevent/ggevent";
import { deskEvent } from "../../../lib/helpers/defaultDeskEvent";
import {
  defaultAttendee,
  roomEvent,
} from "../../../lib/helpers/defaultRoomEvent";
import { ERROR_STRINGS, INFO_STRINGS } from "../../../lib/utils/constants";

interface DeskReserve {
  accountId: string;
  customerID: string;
  deskId: string;
  startTime: string;
  endTime: string;
  checkInReminderSend: boolean;
  organizer?: Attendee;
}

interface RoomReserve {
  startTime: string;
  endTime: string;
  roomId: string;
  attendees: Attendee[];
  organizerEmail: string;
  calendarId: string;
  calendarproviderId: string;
  title: string;
  isOnlineMeeting: boolean;
  isPrivate: boolean;
  checkInReminderSend: boolean;
}

export const useReserveHelpers = () => {
  const { reserveDesk } = useDeskBookingRequest();
  const { reserveRoom } = useBookingRequest();
  const { getWorkplaceLocationPath } = useWorkplacesClient();
  const [locationPath, setLocationPath] = useState<LocationItem[]>([]);
  const [organizerName, setOrganizerName] = useState<string | undefined>("");
  const { isOpen, toggleModal } = useToggleModal();
  const { rootId } = useRootIdQuery();
  const { timeZone } = useTimeZoneContext();
  const { allDay, notifyAssignee } = useBookingsMapContext();

  const handleReserveDesk = async ({
    accountId,
    customerID,
    deskId,
    startTime,
    endTime,
    checkInReminderSend,
    organizer,
  }: DeskReserve) => {
    try {
      const {
        response: { locationID },
      } = await reserveDesk({
        accountID: accountId,
        customerID: customerID,
        deskID: deskId,
        event: {
          ...deskEvent,
          allDay: allDay,
          startTime: startTime,
          endTime: endTime,
          eventTimeZone: timeZone,
          checkInReminderSend: checkInReminderSend,
          notifyAssignee: notifyAssignee,
          organizer: organizer,
        },
      });

      const {
        response: { path },
      } = await getWorkplaceLocationPath({
        onlyFree: false,
        filterTags: [],
        locationID: locationID || rootId,
        customerID: "",
        allDesks: false,
        ignoreBlocked: false,
        timeZone: timeZone,
        page: 1,
        pageSize: 8,
      });

      setLocationPath(path);
      toggleModal();
    } catch (error: any) {
      toast.error(error.message || ERROR_STRINGS.reservationFail);
      console.log(error);
    }
  };

  const handleReserveRoom = async ({
    attendees,
    startTime,
    endTime,
    organizerEmail,
    roomId,
    calendarId,
    calendarproviderId,
    title,
    isOnlineMeeting,
    isPrivate,
    checkInReminderSend,
  }: RoomReserve) => {
    try {
      const {
        response: { locationID, meeting, isExternalBooking },
      } = await reserveRoom({
        calendarId: calendarId,
        onBehalfUserId: "",
        calendarproviderId: calendarproviderId,
        roomID: roomId,
        meeting: {
          ...roomEvent,
          allDay: allDay,
          attendees: attendees,
          checkInRequiredStatus: CheckInRequiredStatus.CHECK_IN_NOT_REQUIRED,
          creationTime: startTime,
          startTime: startTime,
          endTime: endTime,
          isPrivate: isPrivate,
          isOnlineMeeting: isOnlineMeeting,
          eventCheckInStatus: EventCheckInStatus.CHECK_IN_NONE,
          title: title,
          eventTimeZone: timeZone,
          checkInReminderSend: checkInReminderSend,
          notifyAssignee: notifyAssignee,
          organizer: {
            ...defaultAttendee,
            email: organizerEmail,
          },
        },
      });

      const {
        response: { path },
      } = await getWorkplaceLocationPath({
        onlyFree: false,
        filterTags: [],
        locationID: locationID || rootId,
        customerID: "",
        allDesks: false,
        ignoreBlocked: false,
        timeZone: timeZone,
        page: 1,
        pageSize: 8,
      });

      if (isExternalBooking) {
        toast.info(INFO_STRINGS.externalRoom);
      }

      setLocationPath(path);
      const organizerFromAttendee = meeting?.attendees.find(
        (attendee) => attendee.mode1Organizer
      );

      if (organizerFromAttendee) {
        setOrganizerName(organizerFromAttendee.displayName);
      } else {
        setOrganizerName(meeting?.organizer?.displayName);
      }

      toggleModal();
    } catch (error: any) {
      toast.error(error.message || ERROR_STRINGS.reservationFail);
      console.log(error);
    }
  };

  return {
    toggleModal,
    isOpen,
    locationPath,
    handleReserveDesk,
    handleReserveRoom,
    organizerName,
  };
};
