import { differenceInMinutes, endOfDay } from "date-fns";
import { MINIMAL_BOOKING_INTERVAL } from "../utils/constants";

export const isEnoughTimeLeftInDay = ({
  endTime,
  startTime,
}: {
  endTime: Date;
  startTime?: Date;
}) => {
  let minutesUntilEndOfDay = differenceInMinutes(
    endOfDay(startTime ?? new Date()),
    endTime
  );
  // hotfix, when endtime is min 45, it returns correct 15, but when its different, it returns -1
  if (minutesUntilEndOfDay !== 15) {
    minutesUntilEndOfDay = minutesUntilEndOfDay + 1;
  }

  return minutesUntilEndOfDay >= MINIMAL_BOOKING_INTERVAL;
};

export const hasEnoughGapBetweenEvents = (
  endTime: Date,
  nextStartTime: Date
) => {
  const minutesBetweenEvents = differenceInMinutes(nextStartTime, endTime);
  return minutesBetweenEvents >= MINIMAL_BOOKING_INTERVAL;
};
