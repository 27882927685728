import React, { PropsWithChildren, useState } from "react";
import { format } from "date-fns";
import { toast } from "react-toastify";

import { useDeskInsightsRequests } from "../../../../api/grpc/insights/useDeskInsightsRequests";
import { useTimeZoneContext } from "../../../../lib/context/TimeZone/TimeZoneContext";
import { useInsightsContext } from "../../Context/InsightsContext";
import { useDeskOverviewInsightsContext } from "../../Context/Desk/Overview/OverviewContext";
import { useRoomInsightsContext } from "../../Context/Room/Overview/RoomInsightsContext";
import { useCustomerPermissions } from "../../../Billings/lib/useCustomerPermissions";

import {
  TimeRangeRequest,
  useInsightsRequests,
} from "../../../../api/grpc/insights/useInsightsRequests";

import { Button } from "../../../shared/Button/Button";
import { MainResources } from "../../../../lib/types/main.types";

export const ExportButton = ({
  pathname,
}: PropsWithChildren<{ pathname: string }>) => {
  const { exportRoomInsights, exportRoomHistoryInsights } =
    useInsightsRequests();
  const { exportDeskInsights, exportDeskHistoryInsights } =
    useDeskInsightsRequests();
  const {
    startTime,
    endTime,
    insightsLocation: { locationId },
  } = useInsightsContext();
  const { timeZone } = useTimeZoneContext();
  const { offset: roomOffset, organizers: roomOrganizers } =
    useRoomInsightsContext();
  const { offset: deskOffset, organizers: deskOrganizers } =
    useDeskOverviewInsightsContext();
  const { isBasicOrBussines } = useCustomerPermissions();

  const [loading, setLoading] = useState(false);

  const handleExport = async () => {
    if (isBasicOrBussines) {
      return toast.error("This feature requires the Pro subscription plan.");
    }

    setLoading(true);
    try {
      const exportType: MainResources = pathname.includes("room")
        ? "room"
        : "desk";

      const variables: TimeRangeRequest = {
        locationID: locationId,
        startDate: format(startTime, "yyyy-MM-dd"),
        endDate: format(endTime, "yyyy-MM-dd"),
        timeZone,
      };

      let dataToDownload: Uint8Array;
      let fileName: string;

      if (pathname.includes("history")) {
        const limit = 250;

        const { response } =
          exportType === "room"
            ? await exportRoomHistoryInsights({
                ...variables,
                offset: roomOffset,
                organizers: roomOrganizers?.map((item) => item.value) || [],
                limit,
              })
            : await exportDeskHistoryInsights({
                ...variables,
                offset: deskOffset,
                organizers: deskOrganizers?.map((item) => item.value) || [],
                limit,
              });

        dataToDownload = response.data;
        fileName = `${
          exportType === "room" ? "Room" : "Desk"
        }-Insights-History.csv`;
      } else {
        const { response } =
          exportType === "room"
            ? await exportRoomInsights(variables)
            : await exportDeskInsights(variables);

        dataToDownload = response.data;
        fileName = `${exportType === "room" ? "Room" : "Desk"}-Insights.csv`;
      }

      let data = new Blob([dataToDownload], { type: "text/csv" });
      let csvURL = window.URL.createObjectURL(data);

      let tempLink = document.createElement("a");
      tempLink.href = csvURL;
      tempLink.setAttribute("download", `${fileName}`);

      document.body.appendChild(tempLink);
      tempLink.click();

      document.body.removeChild(tempLink);
      tempLink.remove();
    } catch (error: any) {
      return toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      title="Export"
      color="third"
      size="medium"
      onClick={() => handleExport()}
      icon="export-icon"
      className="InsightsHeaderButtons__export"
      disabled={loading}
    />
  );
};
