import React, { PropsWithChildren } from "react";
import { IconDefinition } from "../../../shared/Icon/Icon.generated";
import { Icon } from "../../../shared/Icon/Icon";
import { InputDescription } from "../../../shared/InputDescription/InputDescription";
import { BookingTypes } from "../../BookingsSearch/BookingsSearch";
import { AttendeeRoomInfo } from "./AttendeeRoomInfo";

export const ReservationListName = ({
  icon,
  status,
  type,
  name,
  isAttendee,
  roomName,
  subject,
}: PropsWithChildren<{
  icon: IconDefinition;
  status: string;
  name: string;
  type: BookingTypes;
  isAttendee?: boolean;
  roomName?: string;
  subject?: string;
}>) => {
  return (
    <div className="ReservationListCard__name flex-a-center">
      <div className="ReservationListCard__icon">
        <Icon icon={icon} />

        {isAttendee && (
          <div className="ReservationListCard__icon--attendee">
            <span>You're an attendee</span>
          </div>
        )}
      </div>
      <div className="overflow-hidden">
        <h6
          className={`pt-1 ${
            status === "Declined" ? "text-decoration-line-through" : ""
          }`}
        >
          {name}
        </h6>
        {type === "room" && isAttendee ? (
          <AttendeeRoomInfo
            name={roomName || ""}
            subject={subject || "(No subject)"}
          />
        ) : (
          ["Declined", "Maybe"].includes(status) && (
            <InputDescription
              title={
                status === "Declined" ? "Room declined" : "Awaitng approval"
              }
              titleColor={status === "Declined" ? "danger" : "warning"}
            />
          )
        )}
      </div>
    </div>
  );
};
