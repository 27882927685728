import React, { useRef } from "react";
import { Button, Form, Input, InputGroup, InputGroupAddon } from "reactstrap";
import cs from "classnames";

import "./SearchBox.scss";

interface Props {
  searchValue: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clearSearchValue: () => void;
  className?: string;
  disabledFilters?: boolean;
}

export const SearchBox = ({
  searchValue,
  onChange,
  clearSearchValue,
  className,
  disabledFilters,
}: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleFocusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const classes = cs("SearchBox", className, {
    disabled: disabledFilters,
    SearchBox__disable: disabledFilters,
  });

  return (
    <div className={classes}>
      <Form className="SearchBox__form">
        <InputGroup>
          <Input
            innerRef={(ref) => {
              inputRef.current = ref as HTMLInputElement; // Explicitly cast the ref
            }}
            value={searchValue}
            placeholder="Search..."
            className="SearchBox__input"
            onChange={onChange}
          />

          <InputGroupAddon addonType="append">
            <Button
              tabIndex={-1}
              type="submit"
              className="SearchBox__btn_submit"
              onClick={(e) => {
                e.preventDefault();
                handleFocusInput();
              }}
            />
          </InputGroupAddon>

          {!!searchValue.length && (
            <InputGroupAddon addonType="append">
              <Button
                type="submit"
                className="SearchBox__btn_clear"
                onClick={(e) => {
                  e.preventDefault();
                  clearSearchValue();
                  handleFocusInput();
                }}
              />
            </InputGroupAddon>
          )}
        </InputGroup>
      </Form>
    </div>
  );
};
