import React from "react";
import { useCheckUserPermissions } from "../../lib/hooks/useCheckUserPermissions";

import { PageHeader } from "../shared/PageHeader/PageHeader";
import { TimeZoneLabel } from "../Insights/shared/TimeZoneLabel/TimeZoneLabel";
import { AllReservationsDescription } from "./shared/AllReservationsDescription";
import { AllReservationsHeaderButtons } from "./shared/AllReservationsHeaderButtons/AllReservationsHeaderButtons";
import { AllReservationsContent } from "./AllReservationsContent/AllReservationsContent";
import { AllReservationContextProvider } from "./shared/context/AllReservationContext/AllReservationContextProvider";
import { RolePermissions } from "../../api/grpc/account/account";

import "./AllReservations.scss";

export const AllReservationsRoute = () => {
  const { checkUserPermission } = useCheckUserPermissions();

  return (
    <AllReservationContextProvider>
      <div className="Reservations AllReservations default-page">
        <div className="position-relative mb-4 pb-3 page-border-bottom">
          <PageHeader
            title="Workspace hub"
            hideBorder
            showUserViewType={
              !checkUserPermission(
                RolePermissions.RESERVATIONS_MEMBER_STYLE_VIEW
              )
            }
          >
            <TimeZoneLabel />
          </PageHeader>

          <div className="d-flex justify-content-between align-items-start">
            <AllReservationsDescription />

            <AllReservationsHeaderButtons />
          </div>
        </div>

        <AllReservationsContent />
      </div>
    </AllReservationContextProvider>
  );
};
