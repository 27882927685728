import React from "react";

import { Map } from "../../../api/graphql/floors/floors";
import { Popup } from "../../shared/Popup/Popup";
import { FloorMapControlButton } from "../FloorMapControlButton/FloorMapControlButton";
import { FloorSettingsPopupForm } from "./FloorSettingsPopupForm/FloorSettingsPopupForm";

interface Props {
  map: Map;
  openMapSettings: boolean;
}

export const FloorSettingsButton = ({ map, openMapSettings }: Props) => {
  return (
    <Popup
      trigger={() => <FloorMapControlButton icon="settings-main" />}
      position="bottom left"
      title="Map Settings"
      description="Passthrough, room labels, and marker visibility settings will impact how users experience the map across products."
      className="max-w-350"
      open={openMapSettings}
    >
      <FloorSettingsPopupForm map={map} />
    </Popup>
  );
};
