import React from "react";
import { useBookingsContext } from "../../Context/BookingsContext";
import { useRouter } from "../../../../lib/hooks/useRouter";

import { RESERVATIONS_MAP_ROOT_PATH } from "../../../../lib/routes/routes";
import { BookingTypes } from "../../BookingsSearch/BookingsSearch";
import { BookingOption } from "./BookingOption";
import { LineTextDivider } from "../../../shared/LineTextDivider/LineTextDivider";

import "./BookingOptions.scss";

export const BookingOptions = () => {
  const { history } = useRouter();
  const {
    emptyActiveTags,
    handleMatchedDesks,
    handleMatchedRooms,
    handleLocationId,
  } = useBookingsContext();

  const handleButtonClick = (type: BookingTypes) => () => {
    emptyActiveTags();
    handleMatchedDesks(null);
    handleMatchedRooms(null);

    // to handle some edge cases, we refresh the location when we go to desk/room booking search form
    // most important edge case is to handle when user add a custom workspace as location and that workspace is deleted
    handleLocationId("refreshedId", "RefreshedId");
    return history.push(`/bookings/reserve/search/${type}`);
  };

  return (
    <div className="BookingOptions mt-6">
      {/* <h4 className="mt-4">Find & reserve workspace</h4> */}

      <BookingOption
        icon="room_icon_18x18"
        title="Room"
        type="room"
        onClick={handleButtonClick("room")}
      />

      <LineTextDivider text="OR" />

      <BookingOption
        icon="desk_icon_18x18"
        title="Desk"
        type="desk"
        onClick={handleButtonClick("desk")}
      />

      <h4 className="mt-1">Browse interactive floorplan</h4>

      <BookingOption
        to={RESERVATIONS_MAP_ROOT_PATH}
        icon="floor-map-2"
        title="Virtual map"
        type="map"
        onClick={() => {}}
      />
    </div>
  );
};
