import React from "react";
import { Form, FormFeedback, FormGroup } from "reactstrap";

import { useCustomerPermissions } from "../../../Billings/lib/useCustomerPermissions";
import { tooltips } from "../../../../lib/utils/tooltips";
import { useAuthContext } from "../../../../lib/context/Auth/AuthContext";

import { RoleSelect } from "../../RoleSelect/RoleSelect";
// import { TeamSelect } from "../../TeamSelect/TeamSelect";
import { SingleUserFormik } from "../SingleUserModal/lib/useSingleUserFormik";
import { InputDescription } from "../../../shared/InputDescription/InputDescription";
import { LabelWithInformationBox } from "../../../shared/LabelWithInformationBox/LabelWithInformationBox";
import { Input } from "../../../shared/Input/Input";
import { DeskSelect } from "../../DeskSelect/DeskSelect";
import { SignInMethodSelect } from "../../SignInMethodSelect/SignInMethodSelect";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { ErrorMessage } from "../../../shared/ErrorMessage/ErrorMessage";

interface Props {
  formik: SingleUserFormik;
}

export const SingleUserForm = (props: Props) => {
  const { formik } = props;

  const { user } = useAuthContext();

  const { isBasicOrBussines, loading, error } = useCustomerPermissions();

  return (
    <Form className="SingleUserForm">
      <FormGroup>
        <LabelWithInformationBox
          for="email"
          direction="left"
          title="Invite with email"
          message={tooltips.people.inviteWithEmail}
        />
        <Input
          type="text"
          id="email"
          name="email"
          onBlur={formik.handleBlur}
          value={formik.values.email}
          onChange={formik.handleChange}
          isTouched={formik.touched.email}
          placeholder="jon.doe@company.com"
          errorMessage={formik.errors.email}
          invalid={!!formik.errors.email && formik.touched.email}
        />
        <InputDescription
          title="Note!"
          description="External email domain users may have limited functionality"
        />
      </FormGroup>
      <FormGroup>
        <LabelWithInformationBox
          for="role"
          title="Role"
          message={tooltips.people.role}
          direction="left"
        />
        <RoleSelect
          fieldName="role"
          value={formik.values.role}
          onChange={(field, value) => {
            formik.setFieldTouched(field, true);
            formik.setFieldValue(field, value);
          }}
        />
        {!!formik.errors.role && formik.touched.role && (
          <FormFeedback className="invalid-feedback">
            {formik.errors.role}
          </FormFeedback>
        )}
      </FormGroup>

      <FormGroup>
        <LabelWithInformationBox
          for="loginMethod"
          title="Sign in method"
          message={tooltips.people.signInMethod}
          direction="left"
        />
        {loading ? (
          <LoadingBox minHeight={40} />
        ) : error ? (
          <ErrorMessage message="Couldn't fetch permission data" />
        ) : (
          <SignInMethodSelect
            fieldName="loginMethod"
            value={formik.values.loginMethod}
            disable={user?.claims.role !== "owner"}
            onChange={(field, value) => {
              formik.setFieldTouched(field, true);
              formik.setFieldValue(field, value);
            }}
            isBasicOrBussines={isBasicOrBussines}
          />
        )}
      </FormGroup>

      <DeskSelect
        onChange={(values) => {
          formik.setFieldValue("desk", [values.value]);
          formik.setFieldTouched("desk", true);
          return;
        }}
        value={formik.values.desk}
      />
      {/* <FormGroup className="disabled">
        <LabelWithInformationBox
          for="team"
          title="Team"
          direction="left"
          message={tooltips.users.team}
        />
        <TeamSelect
          fieldName="team"
          value={formik.values.team}
          onChange={(field, value) => {
            formik.setFieldValue(field, value);
            formik.setFieldTouched(field, true);
          }}
        />
      </FormGroup> */}
    </Form>
  );
};
