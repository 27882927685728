import React, { useEffect, useMemo } from "react";

import { Form, FormGroup } from "reactstrap";
import DatePicker from "react-datepicker";
import {
  addMinutes,
  differenceInMinutes,
  format,
  parseISO,
  setHours,
  setMinutes,
  setSeconds,
  subMinutes,
  differenceInDays,
  startOfDay,
  addHours,
} from "date-fns";

import { useSidebarContext } from "../../../lib/context/Sidebar/SidebarContext";
import { useBookingsContext } from "../Context/BookingsContext";
import { useTimeZoneContext } from "../../../lib/context/TimeZone/TimeZoneContext";
import { utcToZonedTime } from "date-fns-tz";
import { useBookingsMapContext } from "../BookingsMap/Context/BookingsMapContext";

import { trimText } from "../../../lib/helpers/trimText";
import { timePickerProps } from "../helpers/timePickerProps";

import { Button } from "../../../components/shared/Button/Button";
import { SearchWorkplaceFormik } from "../lib/useSearchWorkplaceFormik";
import { TagsButton } from "./TagsButton/TagsButton";
import { FindPeopleButton } from "./FindPeopleButton/FindPeopleButton";
import { Capabilities } from "./Capabilities/Capabilities";
import { Input } from "../../shared/Input/Input";
import { LabelWithInformationBox } from "../../shared/LabelWithInformationBox/LabelWithInformationBox";
import { BookingTypes } from "../BookingsSearch/BookingsSearch";
import { defineDatesPicker } from "../lib/defineDatesPicker";
import { GetDefaultLocationPathResponse } from "../../../api/graphql/bookings/bookings";
import { BookingsSearchButton } from "../BookingsSearch/BookingsSearchButton/BookingsSearchButton";
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState";
import { LoadingBox } from "../../shared/LoadingBox/LoadingBox";
import { Switch } from "../../shared/Switch/Switch";
import { DaySelectorInput } from "../../shared/Forms/DaySelectorInput/DaySelectorInput";
import { RoomBookingInputs } from "./RoomBookingInputs/RoomBookingInputs";
import { EndTimeInputs } from "./EndTimeInputs/EndTimeInputs";
import {
  BOOKING_INTERVAL,
  DESK_BOOKING_INTERVAL,
} from "../../../lib/utils/constants";

import "react-datepicker/dist/react-datepicker.css";
import "./SearchWorkplaceForm.scss";

interface Props {
  formik: SearchWorkplaceFormik;
  type: BookingTypes;
  defaultLocation?: GetDefaultLocationPathResponse;
  loading?: boolean;
}

export const SearchWorkplaceForm = ({
  formik,
  type,
  defaultLocation,
  loading,
}: Props) => {
  const INTERVAL = type === "room" ? BOOKING_INTERVAL : DESK_BOOKING_INTERVAL;
  const { togglePageSidebar } = useSidebarContext();
  const { timeZone } = useTimeZoneContext();
  const {
    location: { locationName },
    activeTagsFilter,
    handleActiveTagsFilter,
    handleReserveDeskVariables,
    reservDeskVariables: { startTime, endTime },
    handleDayChange,
    handleIsOnlineMeeting,
    isOnlineMeeting,
    handleMakePrivate,
    makePrivate,
    handleSubject,
  } = useBookingsContext();
  const { allDay, setAllDay } = useBookingsMapContext();

  const { datePickOptions } = defineDatesPicker(timeZone);

  let pickedDayFormated = parseISO(formik.values.day.value);

  const filterPassedTime = (time: Date) => {
    if (
      differenceInDays(
        startOfDay(pickedDayFormated),
        startOfDay(utcToZonedTime(new Date(), timeZone))
      ) > 0
    ) {
      return true;
    }
    const currentDate = utcToZonedTime(new Date(), timeZone);
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const handleDurationChange = (hours: number, minutes: number) => {
    const currentDate = utcToZonedTime(new Date(startTime), timeZone);
    const updatedEndTime = addMinutes(addHours(currentDate, hours), minutes);

    handleReserveDeskVariables(currentDate, updatedEndTime);

    formik.setFieldValue("from", currentDate);
    formik.setFieldValue("until", updatedEndTime);
  };

  const locationNameForSearch = useMemo(() => {
    if (locationName && locationName !== "RefreshedId") {
      return locationName;
    }

    return "All locations";
  }, [locationName]);

  useEffect(() => {
    const currentDate = utcToZonedTime(new Date(), timeZone);
    handleReserveDeskVariables(currentDate, addMinutes(currentDate, INTERVAL));
    //we have to parseISO date because for diefferent timezones it would not work stable and take -1 day
    handleDayChange(parseISO(format(currentDate, "yyyy-MM-dd")));
  }, []);

  return (
    <Form className="SearchWorkplaceForm">
      {type === "room" && (
        <FormGroup className="SearchWorkplaceForm__subject SearchWorkplaceForm--text">
          <Input
            type="text"
            placeholder="Subject new reservation..."
            id="subject"
            name="subject"
            value={formik.values.subject}
            onChange={(e) => {
              handleSubject(e.target.value);
              formik.setFieldValue("subject", e.target.value);
            }}
            isTouched={formik.touched.subject}
            errorMessage={formik.errors.subject}
            className={formik.errors.subject ? "form-error" : ""}
          />
        </FormGroup>
      )}

      <FormGroup className="SearchWorkplaceForm--text mb-0">
        <label className="pb-2">From</label>
        <DaySelectorInput
          onChange={(values) => {
            if (values === null) {
              return;
            }

            handleDayChange(parseISO(values.value));
            formik.setFieldValue("day", values);
            formik.setFieldTouched("day", true);

            if (allDay === true) {
              const definedTimeEndTime = setHours(
                setMinutes(
                  setSeconds(utcToZonedTime(new Date(), timeZone), 0),
                  0
                ),
                24
              );

              const definedTimeStartTime =
                differenceInDays(
                  startOfDay(parseISO(values.value)),
                  startOfDay(utcToZonedTime(new Date(), timeZone))
                ) > 0
                  ? setHours(
                      setMinutes(
                        setSeconds(utcToZonedTime(new Date(), timeZone), 0),
                        0
                      ),
                      24
                    )
                  : utcToZonedTime(new Date(), timeZone);

              handleReserveDeskVariables(
                definedTimeStartTime,
                definedTimeEndTime
              );

              formik.setFieldValue("until", definedTimeEndTime);
              formik.setFieldValue("from", definedTimeStartTime);
              formik.setFieldTouched("until", true);
              formik.setFieldTouched("from", true);
            }
            return;
          }}
          value={
            formik.values.day.value
              ? {
                  label: formik.values.day.label,
                  value: formik.values.day.value,
                }
              : datePickOptions[0]
          }
        />
      </FormGroup>

      <FormGroup className="SearchWorkplaceForm--text">
        <DatePicker
          {...timePickerProps}
          selected={startTime}
          onChange={(date) => {
            if (!date) {
              return;
            }
            let diffMinutes = differenceInMinutes(date, endTime);
            let shouldUpdateEndTime =
              (date >= endTime ||
                (diffMinutes >= -BOOKING_INTERVAL && diffMinutes < 0)) &&
              !allDay;

            handleReserveDeskVariables(
              date,
              shouldUpdateEndTime
                ? addMinutes(new Date(date), BOOKING_INTERVAL)
                : endTime
            );

            if (shouldUpdateEndTime) {
              formik.setFieldValue(
                "until",
                addMinutes(new Date(date), BOOKING_INTERVAL)
              );
            }

            formik.setFieldValue("from", date);

            formik.setFieldTouched("from", true);

            return;
          }}
          className="pb-1 SearchWorkplaceForm__timePicker"
          filterTime={filterPassedTime}
        />
      </FormGroup>

      <FormGroup className="SearchWorkplaceForm--text flex-a-center">
        <EndTimeInputs
          handleDurationChange={handleDurationChange}
          startTime={startTime}
          endTime={endTime}
          filterPassedTime={filterPassedTime}
          onChange={(date) => {
            if (!date) {
              return;
            }
            handleReserveDeskVariables(
              date <= startTime
                ? subMinutes(new Date(date), BOOKING_INTERVAL)
                : startTime,
              date
            );

            if (date <= startTime && !allDay) {
              formik.setFieldValue(
                "from",
                subMinutes(new Date(date), BOOKING_INTERVAL)
              );
            }

            formik.setFieldValue("until", date);
            formik.setFieldTouched("until", true);

            return;
          }}
        />
      </FormGroup>

      <div className="flex-a-center SearchWorkplaceForm__switch justify-content-center mb-3">
        <Switch
          name="allDay"
          title="Allday"
          value={allDay}
          onChange={() => {
            const definedTimeEndTime =
              allDay === false
                ? setHours(
                    setMinutes(
                      setSeconds(utcToZonedTime(new Date(), timeZone), 0),
                      0
                    ),
                    24
                  )
                : utcToZonedTime(
                    addMinutes(new Date(), BOOKING_INTERVAL),
                    timeZone
                  );

            const definedTimeStartTime =
              allDay === false &&
              differenceInDays(
                startOfDay(pickedDayFormated),
                startOfDay(utcToZonedTime(new Date(), timeZone))
              ) > 0
                ? setHours(
                    setMinutes(
                      setSeconds(utcToZonedTime(new Date(), timeZone), 0),
                      0
                    ),
                    24
                  )
                : utcToZonedTime(new Date(), timeZone);

            handleReserveDeskVariables(
              definedTimeStartTime,
              definedTimeEndTime
            );

            formik.setFieldValue("until", definedTimeEndTime);
            formik.setFieldValue("from", definedTimeStartTime);
            formik.setFieldTouched("until", true);
            formik.setFieldTouched("from", true);

            setAllDay((prev) => !prev);
          }}
        />
      </div>

      <FormGroup>
        <FindPeopleButton type={type} />
      </FormGroup>

      {type === "room" && (
        <RoomBookingInputs
          makePrivate={makePrivate}
          onChangePrivate={() => handleMakePrivate()}
          isOnlineMeeting={isOnlineMeeting}
          onChangeOnlineMeeting={() => handleIsOnlineMeeting()}
        />
      )}

      <FormGroup className="SearchWorkplaceForm--text mt-3">
        <LabelWithInformationBox
          title="Location"
          for="location"
          informationBoxClassName="pt-2"
        />

        <HandleLoadingState
          loading={loading}
          loadingPlaceholder={<LoadingBox minHeight={50} />}
        >
          {defaultLocation?.queryDefaultLocationPath[0]?.referenceId &&
          (!!!locationName.length || locationName === "RefreshedId") ? (
            <BookingsSearchButton
              id={defaultLocation.queryDefaultLocationPath[0].referenceId || ""}
              togglePageSidebar={togglePageSidebar}
              className="SearchWorkplaceForm__location pt-2"
            />
          ) : (
            <Button
              title={trimText(locationNameForSearch ?? "", 25)}
              color="third"
              icon="location-reserve"
              onClick={togglePageSidebar}
              className="SearchWorkplaceForm__location pt-2"
            />
          )}
        </HandleLoadingState>
      </FormGroup>

      <FormGroup className="flex-a-center SearchWorkplaceForm__couple mt-4">
        <TagsButton
          onClick={(e) => {
            handleActiveTagsFilter(e);
          }}
          hasActiveTags={activeTagsFilter.length > 0}
          type={type}
        />

        {type === "room" && <Capabilities />}
      </FormGroup>
    </Form>
  );
};
