import React from "react";
import cs from "classnames";
import { PageHeader } from "../../../components/shared/PageHeader/PageHeader";
import { LineDivider } from "../../shared/LineDivider/LineDivider";

import "./ReserveCard.scss";

interface Props {
  children: JSX.Element;
  footer?: JSX.Element;
  type?: string;
  workplaceType?: string;
  className?: string;
}

export const ReserveCard = ({
  children,
  footer,
  type,
  workplaceType,
  className,
}: Props) => {
  const classes = cs("ReserveCard", className, {
    ReserveCard__main: type === "main",
  });

  return (
    <div className={classes}>
      <PageHeader
        title={workplaceType ? `Reserve a ${workplaceType}` : "Find & reserve"}
        hideBorder
        icon={
          workplaceType
            ? workplaceType === "desk"
              ? "desk_icon_18x18"
              : "room_icon_18x18"
            : undefined
        }
      />
      <LineDivider />
      <div className="ReserveCard__content">
        {children}
        {footer}
      </div>
    </div>
  );
};
