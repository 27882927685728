import React, { PropsWithChildren } from "react";

import { DateRangeButton } from "../DateRangeButton/DateRangeButton";
import { ExportButton } from "../ExportButton/ExportButton";
import { LocationInsightsButton } from "../LocationInsightsButton/LocationInsightsButton";
import { RolePermissions } from "../../../../api/grpc/account/account";
import { PermissionWrapper } from "../../../shared/PermissionWrapper/PermissionWrapper";
import { AdjustableButtons } from "../../../shared/AdjustableButtons/AdjustableButtons";

import "./InsightsHeaderButtons.scss";

export const InsightsHeaderButtons = ({
  pathname,
}: PropsWithChildren<{ pathname: string }>) => {
  return (
    <div className="InsightsHeaderButtons">
      <AdjustableButtons>
        <DateRangeButton />

        <LocationInsightsButton />

        <PermissionWrapper
          permission={[
            RolePermissions.INSIGHTS_DESK_EXPORT_CSV,
            RolePermissions.INSIGHTS_ROOM_EXPORT_CSV,
          ]}
        >
          <ExportButton pathname={pathname} />
        </PermissionWrapper>
      </AdjustableButtons>
    </div>
  );
};
