import React from "react";
import { useBookingsContext } from "../../../Context/BookingsContext";
import { UserInfoRowItem } from "../../../shared/UserModal/UserInfoRowItem";
import { seating } from "../capabilitiesOptions";
import { Label } from "reactstrap";

export const Seatings = () => {
  const { seats, handleSeats } = useBookingsContext();

  const handleSeatingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSeats(e.target.value);
  };

  return (
    <div className="Seatings">
      <UserInfoRowItem icon="users-group" content="Seating" />
      <div className="pt-1">
        {seating.map((seat) => (
          <div key={seat}>
            <input
              id={seat}
              type="radio"
              value={seat}
              name="seatings"
              checked={seats === seat}
              onChange={handleSeatingChange}
            />
            <Label htmlFor={seat} className="checkbox_label pl-0">
              {seat}
            </Label>
          </div>
        ))}
      </div>
    </div>
  );
};
