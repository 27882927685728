import React from "react";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { RolePermissions } from "../../../api/grpc/account/account";
import { ALL_RESERVATION_LIMIT } from "../../../lib/utils/constants";

export const AllReservationsDescription = () => {
  const { user } = useAuthContext();

  return (
    <div className="AllReservationsDescription w-50-large">
      {user?.permissions.includes(
        RolePermissions.RESERVATIONS_MEMBER_STYLE_VIEW
      ) ? (
        <>
          <span className="text-secondary">
            Your central hub for booking and managing all workspace
            reservations.
          </span>{" "}
          <span className="text-secondary">
            Add up to {ALL_RESERVATION_LIMIT} rooms or desks to your dashboard
            for an instant overview of their reservation statuses.
          </span>
          <ul className="pro-list mt-2">
            <li>
              Manage Bookings:{" "}
              <span className="text-secondary">
                Easily create, modify, or cancel your own reservations.
              </span>
            </li>
          </ul>
        </>
      ) : (
        <>
          <span className="text-secondary">
            For admin roles with coordinator privileges, use the “Workspace hub”
            section to efficiently manage workspace reservations across specific
            dates and locations:
          </span>
          <ul className="pro-list mt-2">
            <li>
              Add & Monitor Workspaces:{" "}
              <span className="text-secondary">
                Add up to {ALL_RESERVATION_LIMIT} rooms or desks to your
                dashboard for a quick comparative overview of their reservation
                statuses.
              </span>
            </li>
            <li>
              Manage Bookings:{" "}
              <span className="text-secondary">
                Create, modify, or cancel bookings effortlessly to accommodate
                your and your team's evolving needs.
              </span>
            </li>
            <li>
              Book on Behalf:{" "}
              <span className="text-secondary">
                Reserve and check in to spaces for colleagues, ensuring that
                both internal coworkers and external guests have the workspace
                they need.
              </span>
            </li>
          </ul>
        </>
      )}
    </div>
  );
};
