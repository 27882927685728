import React, { useEffect, useState } from "react";
import { useInsightsContext } from "../../Context/InsightsContext";
import { useRoomInsightsContext } from "../../Context/Room/Overview/RoomInsightsContext";

import { RoomHistoryTable } from "./RoomHistoryTable";
import { HistoryFilters } from "../../shared/Filters/HistoryFilters";
import { HandleLoadingState } from "../../../shared/HandleLoadingState/HandleLoadingState";
import { LoadingBox } from "../../../shared/LoadingBox/LoadingBox";
import { HandleRequestState } from "../../../shared/HandleRequestState/HandleRequestState";
import { CenteredBox } from "../../../shared/CenteredBox/CenteredBox";
import { Button } from "../../../shared/Button/Button";
import { RoomHistoryEventsResponse } from "../../../../api/grpc/insights/insights";

export const RoomHistory = () => {
  const {
    startTime,
    endTime,
    insightsLocation: { locationId },
  } = useInsightsContext();

  const {
    loadingRoomHistory,
    errorRoomHistory,
    dataRoomHistory,
    setOffset,
    organizers,
    setOrganizers,
  } = useRoomInsightsContext();

  const [roomHistoryData, setRoomHistoryData] = useState<
    RoomHistoryEventsResponse | undefined
  >(undefined);

  useEffect(() => {
    if (organizers?.length) {
      setOrganizers(undefined);
    }
  }, []);

  useEffect(() => {
    setRoomHistoryData(undefined);
    setOffset(0);
  }, [organizers, startTime, endTime, locationId]);

  useEffect(() => {
    if (dataRoomHistory?.results?.length) {
      setRoomHistoryData((prevData) => ({
        totalRecords: dataRoomHistory.totalRecords,
        results: [...(prevData?.results || []), ...dataRoomHistory.results],
      }));
    }
  }, [dataRoomHistory]);

  return (
    <div className="RoomHistory">
      <HistoryFilters
        organizers={organizers}
        setOrganizers={setOrganizers}
        disbaled={loadingRoomHistory || !!errorRoomHistory}
      />

      <HandleLoadingState
        loading={loadingRoomHistory && !!!roomHistoryData?.results.length}
        loadingPlaceholder={<LoadingBox count={5} minHeight={60} />}
      >
        <HandleRequestState
          state={!!errorRoomHistory}
          placeholder={
            <CenteredBox>
              {errorRoomHistory?.message || "Error fetching room history"}
            </CenteredBox>
          }
        >
          {roomHistoryData && (
            <>
              <RoomHistoryTable dataRoomHistory={roomHistoryData} />

              {/* loader for new requests */}
              {loadingRoomHistory && <LoadingBox count={5} minHeight={65} />}

              {roomHistoryData.results.length <
                roomHistoryData.totalRecords && (
                <div className="text-center mt-4">
                  <Button
                    title="More items"
                    size="small"
                    color="primary"
                    onClick={() => setOffset((prev) => prev + 250)}
                  />
                </div>
              )}
            </>
          )}
          {!!!dataRoomHistory?.results.length && (
            <CenteredBox>
              <span>There are no room bookings.</span>
            </CenteredBox>
          )}
        </HandleRequestState>
      </HandleLoadingState>
    </div>
  );
};
