import React from "react";
import { Form, FormGroup } from "reactstrap";

import { useLicensesContext } from "../../../../lib/context/Customer/CustomerContext";
import { Status } from "../../../shared/Status/Status";
import { RoleSelect } from "../../RoleSelect/RoleSelect";
// import { TeamSelect } from "../../TeamSelect/TeamSelect";
import { EditUserFormik } from "../lib/useEditUserFormik";
import { LabelWithInformationBox } from "../../../shared/LabelWithInformationBox/LabelWithInformationBox";

import { Input } from "../../../shared/Input/Input";
import { Switch } from "../../../shared/Switch/Switch";
import { tooltips } from "../../../../lib/utils/tooltips";
import { DeskSelect } from "../../DeskSelect/DeskSelect";
import { SignInMethodSelect } from "../../SignInMethodSelect/SignInMethodSelect";
import { EntityType } from "../../../../api/grpc/subscription/subscription";

interface Props {
  formik: EditUserFormik;
  defineLicenseNumber?: () => JSX.Element;
  refetch: () => void;
}

export const EditUserForm = ({
  formik,
  defineLicenseNumber,
  refetch,
}: Props) => {
  const { handleLicenseUpdate } = useLicensesContext();

  return (
    <Form>
      <FormGroup>
        <LabelWithInformationBox
          title="Status"
          direction="left"
          message={tooltips.people.edit}
        />
        <Status status={formik.values.status} />
      </FormGroup>
      <FormGroup>
        <LabelWithInformationBox
          title="Email ID"
          message={tooltips.people.email}
          direction="left"
        />
        <Input readOnly type="text" value={formik.values.emailID} />
      </FormGroup>
      <FormGroup>
        <LabelWithInformationBox
          direction="left"
          title="First name"
          message={tooltips.people.firstName}
        />
        <Input
          type="text"
          name="firstName"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.firstName}
          isTouched={formik.touched.firstName}
          errorMessage={formik.errors.firstName}
          invalid={!!formik.errors.firstName && formik.touched.firstName}
        />
      </FormGroup>
      <FormGroup>
        <LabelWithInformationBox
          direction="left"
          title="Last name"
          message={tooltips.people.lastName}
        />
        <Input
          type="text"
          name="lastName"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.lastName}
          isTouched={formik.touched.lastName}
          errorMessage={formik.errors.lastName}
          invalid={!!formik.errors.lastName && formik.touched.lastName}
        />
      </FormGroup>
      <FormGroup>
        <LabelWithInformationBox
          for="role"
          title="Role"
          direction="left"
          message={tooltips.people.role}
        />
        <RoleSelect
          fieldName="role"
          value={formik.values.role}
          onChange={(field, value) => {
            formik.setFieldValue(field, value);
            formik.setFieldTouched(field, true);
          }}
        />
      </FormGroup>

      <FormGroup>
        <LabelWithInformationBox
          for="loginMethod"
          title="Sign in method"
          message={tooltips.people.signInMethod}
          direction="left"
        />
        <SignInMethodSelect
          fieldName="loginMethod"
          value={formik.values.loginMethod}
          disable={true}
          onChange={(field, value) => {
            formik.setFieldValue(field, value);
            formik.setFieldTouched(field, true);
          }}
        />
      </FormGroup>

      <DeskSelect
        onChange={(values) => {
          formik.setFieldValue("desk", [values.value]);
          formik.setFieldTouched("desk", true);
          return;
        }}
        value={formik.values.desk}
      />
      {/* <FormGroup className="disabled">
        <LabelWithInformationBox
          for="team"
          title="Team"
          direction="left"
          message={tooltips.users.team}
        />
        <TeamSelect
          fieldName="team"
          value={formik.values.team}
          onChange={(field, value) => {
            formik.setFieldValue(field, value);
            formik.setFieldTouched(field, true);
          }}
        />
      </FormGroup> */}
      {/* <LabelWithInformationBox
        title="Assign license"
        message={tooltips.people.license}
        direction="left"
      /> */}
      <Switch
        name="licensed"
        value={formik.values.licensed}
        title="Assign license"
        message={tooltips.people.license}
        direction="left"
        onChange={async (value) => {
          try {
            const response = await handleLicenseUpdate({
              id: formik.values.id,
              value,
              type: EntityType.USER,
            });

            if (response) {
              formik.setFieldValue("licensed", value);
              formik.setFieldTouched("licensed", true);

              refetch();
            }

            return;
          } catch (error: any) {
            console.error(error.toString());
          }
        }}
      />

      {defineLicenseNumber && defineLicenseNumber()}
    </Form>
  );
};
