import { useMemo } from "react";
import { Size } from "../FloorPlan/FloorsPlan";

const MULTIPLIER = 2.5;

export const useDefineBounds = (mapImageSize: Size | null) => {
  const overlayBounds = useMemo<L.LatLngBoundsLiteral | null>(() => {
    if (!mapImageSize) {
      return null;
    }

    return [
      [mapImageSize.height / 2, mapImageSize.width / 2],
      [-mapImageSize.height / 2, -mapImageSize.width / 2],
    ];
  }, [mapImageSize]);

  const mapBounds = useMemo<L.LatLngBoundsLiteral | null>(() => {
    if (!mapImageSize) {
      return null;
    }

    return [
      [mapImageSize.height / 2, 0],
      [-mapImageSize.height / 2, -0],
    ];
  }, [mapImageSize]);

  const mapMaxBounds = useMemo<L.LatLngBoundsLiteral | null>(() => {
    if (!overlayBounds) {
      return null;
    }

    return [
      [overlayBounds[0][0] * MULTIPLIER, overlayBounds[0][1]],
      [overlayBounds[1][0] * MULTIPLIER, overlayBounds[1][1]],
    ];
  }, [overlayBounds]);

  return { overlayBounds, mapBounds, mapMaxBounds };
};
