import React from "react";
import { WorkplaceFields } from "../../../api/graphql/workplaces";
import { tooltips } from "../../../lib/utils/tooltips";
import { WayFinderFormik } from "../../shared/Forms/WayFinderForm/lib/useWayFinderFormik";
import { WayFinderTreeLevel } from "./WayFinderTreeLevel";
import { WayFinderWorkspaceFooterPicker } from "./WayFinderWorkspaceFooterPicker";
import { WorkspacePicker } from "../../shared/WorkspacePicker/WorkspacePicker";

interface Props {
  closePicker: () => void;
  handleWorkspace: (
    item: WorkplaceFields,
    type: "desks" | "rooms",
    methodType: "add" | "delete"
  ) => void;
  clearWorkspaces: () => void;
  formik: WayFinderFormik;
  maxNumberOfRooms: boolean;
  maxNumberOfDesks: boolean;
}

export const WayFinderWorkspacePicker = ({
  closePicker,
  handleWorkspace,
  formik,
  clearWorkspaces,
  maxNumberOfRooms,
  maxNumberOfDesks,
}: Props) => {
  return (
    <WorkspacePicker
      message={tooltips.wayfinders.settings.picker}
      description="Pick your rooms and desks"
      closePicker={closePicker}
      footer={
        <WayFinderWorkspaceFooterPicker
          desksCounter={formik.values.desks.length}
          roomsCounter={formik.values.rooms.length}
          clearWorkspaces={clearWorkspaces}
          handleConfirm={closePicker}
        />
      }
    >
      {({ rootWorkplaces }) => {
        return rootWorkplaces.map((item) => {
          if (typeof item !== "object") {
            return null;
          }

          return item.map((workplace) => (
            <WayFinderTreeLevel
              item={workplace}
              toggleParent={() => {}}
              key={`${workplace.id}-tree-root`}
              handleWorkspace={handleWorkspace}
              formik={formik}
              maxNumberOfDesks={maxNumberOfDesks}
              maxNumberOfRooms={maxNumberOfRooms}
            />
          ));
        });
      }}
    </WorkspacePicker>
  );
};
