import React, { useEffect } from "react";
import { useWorkplaceRequests } from "../../../api/graphql/useWorkplaceRequests";
import { useAuthContext } from "../../../lib/context/Auth/AuthContext";
import { FilteredWorkplacesTree } from "../Workplaces/FilteredWorkplaces/FilteredWorkplacesTree";
import { FloorRoutes } from "./FloorRoutes";
import { useRouter } from "../../../lib/hooks/useRouter";
import {
  FLOORPLAN_ROOT_PATH,
  RESERVATIONS_MAP_ROOT_PATH,
} from "../../../lib/routes/routes";

import "./Floors.scss";

export const Floors = () => {
  const { user } = useAuthContext();
  const { pathname, history } = useRouter();
  const { getRootWorkplaces } = useWorkplaceRequests();

  const [request, { loading, error, data }] = getRootWorkplaces;

  const rootWorkplaces = Object.values(data?.queryTenant[0] || {}).filter(
    (item) => typeof item === "object"
  );

  useEffect(() => {
    request({ variables: { tenantId: user?.customerid || "" } });
  }, []);

  return (
    <div className="Floors default-page">
      <FilteredWorkplacesTree
        showOnDesktop={false}
        loading={loading}
        error={error}
        rootWorkplaces={rootWorkplaces}
        showOnInitialization={pathname === FLOORPLAN_ROOT_PATH ? true : false}
        hideToggler={pathname === FLOORPLAN_ROOT_PATH}
        className="PageSidebar--floors"
        onTitleClick={() => {
          if (pathname === FLOORPLAN_ROOT_PATH) {
            return;
          }
          history.push(FLOORPLAN_ROOT_PATH);
        }}
        pathTo={
          pathname.includes(FLOORPLAN_ROOT_PATH)
            ? `${FLOORPLAN_ROOT_PATH}/floor`
            : RESERVATIONS_MAP_ROOT_PATH
        }
      />

      <FloorRoutes />
    </div>
  );
};
