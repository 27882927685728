import React, { PropsWithChildren } from "react";
import { InputDescription } from "../../../shared/InputDescription/InputDescription";
import { trimText } from "../../../../lib/helpers/trimText";

export const AttendeeRoomInfo = ({
  name,
  subject,
}: PropsWithChildren<{
  name: string;
  subject: string;
}>) => {
  return (
    <div className="AttendeeRoomInfo">
      <InputDescription
        title={`${trimText(subject, 35)}`}
        titleColor="theme"
        icon="rooms-icon"
        description={name}
      />
    </div>
  );
};
