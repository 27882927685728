import React from "react";
import cs from "classnames";
import { Icon } from "../Icon/Icon";
import { IconDefinition } from "../Icon/Icon.generated";

import "./InputDescription.scss";

interface Props {
  title?: string;
  description?: string;
  color?: "theme" | "danger";
  titleColor?: "theme" | "danger" | "warning" | "info";
  className?: string;
  adjustable?: boolean;
  icon?: IconDefinition;
}

export const InputDescription = ({
  title,
  description,
  color,
  className,
  titleColor,
  adjustable,
  icon,
}: Props) => {
  const classes = cs("InputDescription", className, {
    "InputDescription--theme": color === "theme",
    "InputDescription--labelTitle": titleColor === "theme",
    "InputDescription--warning": titleColor === "warning",
    "InputDescription--info": titleColor === "info",
    "InputDescription--adjustable": adjustable,
  });

  return (
    <div className={classes}>
      {title && <h5>{title}</h5>}
      {icon && <Icon icon={icon} />}
      {description && <p>{description}</p>}
    </div>
  );
};
